import React from "react";
import { graphql } from "gatsby";
import Layout from "../containers/layout";
import GraphQLErrorList from "../components/graphql-error-list";
import Seo from "../components/seo";
import { Helmet } from 'react-helmet';

import PageHeader from "../components/career-page-header";
import PostMain from "../components/post-main";
import ApplicationInfo from "../components/career-application-info";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query CareerTemplateQuery($id: String!) {
    careersLanding: sanityCareersLanding {
      themePurple
      subtitle
      _rawApplicationInfo
    }

    career: sanityCareer(id: { eq: $id }) {
      id
      title
      type {
        title
      }
      location {
        title
      }
      thumbnail {
        ...SanityImage
        alt
      }
      flexibleContent {
        ... on SanityFcContentBlock {
          _key
          _type
          _rawContent
        }
        ... on SanityFcVideo {
          _key
          _type
          caption
          url
        }
      }
      seo {
        title
        description
        thumbnail {
          asset {
            _id
          }
          alt
        }
      }
    }
  }
`;

const CareerTemplate = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }
  
  const careersLanding = (data || {}).careersLanding;
  const career = (data || {}).career;

  const title = career.seo?.title || career.title;
  const description = career.seo?.description;
  const image = career.seo?.thumbnail || career.thumbnail;
  const autoSiteTitle = !career.seo?.title;

  return (
    <Layout>
      <Seo
        title={title}
        description={description}
        image={image}
        autoSiteTitle={autoSiteTitle}
      />

      <Helmet>
        <html className={careersLanding.themePurple ? "theme-purple" : undefined} lang="en" />
      </Helmet>

      {career.title && (<PageHeader title={career.title} landingTitle={careersLanding.subtitle} type={career.type} location={career.location} image={career.thumbnail} />)}
      {career.flexibleContent && (<PostMain content={career.flexibleContent} title={career.title} />)}
      {careersLanding._rawApplicationInfo && (<ApplicationInfo content={careersLanding._rawApplicationInfo} />)}
    </Layout>
  );
};

export default CareerTemplate;
