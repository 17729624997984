import React from "react";
import Container from "./container";
import { cn } from "../lib/helpers";
import PortableText from "./portableText";

import * as styles from "../styles/components/career-application-info.module.css";

const ApplicationInfo = (props) => {

  const {
    content
  } = props;

  return (
    <div className={styles.root}>
      <Container>
        {content && (<div className={cn("entry-content", styles.contentWrap)}><PortableText blocks={content} /></div>)}
      </Container>
    </div>
  );
}

export default ApplicationInfo;
